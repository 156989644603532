export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    item: [
      {
        field: 'parameterModule',
        search: true,
        title: '所属模块',
        type: 'select',
        apiUrl: '/mdm/mdmdictdata/list',
        optionsKey: {
          label: 'dictValue',
          value: 'dictCode',
        },
        paramsFixed: {
          dictTypeCode: 'module_group',
        },
      },
      {
        field: 'parameterCode',
        type: 'input',
        title: '参数',
        search: true,
      },
      {
        field: 'parameterName',
        type: 'input',
        title: '参数名称',
        search: true,
      },
      {
        field: 'parameterValue',
        type: 'input',
        title: '参数值',
        search: true,
      },
    ],
  },
  toolBarConfig: [
    {
      type: 'button',
      // buttonType: 'primary',
      // icon: 'el-icon-plus',
      code: 'add',
      name: '新增',
    },
    {
      type: 'button',
      buttonType: null,
      // icon: 'el-icon-delete',
      name: '删除',
      code: 'delete',
    },
    {
      type: 'button',
      // buttonType: 'primary',
      // icon: 'el-icon-folder',
      code: 'journal',
      name: '日志',
    },
  ],
  columns: [
    {
      fixed: 'left', // 固定位置在左边
      type: 'checkbox', // 单选
      title: null,
      width: '50',
    },
    {
      fixed: 'left',
      type: 'seq', // 显示序号
      title: '序号',
    },
    {
      field: 'parameterModuleName',
      title: '所属模块',
    },
    {
      field: 'parameterCode',
      title: '参数',
    },
    {
      field: 'parameterName',
      title: '参数名称',
    },
    {
      field: 'defaultValue',
      title: '默认值',
    },
    {
      field: 'parameterValue',
      title: '参数值',
    },
    {
      field: 'parameterExplain',
      title: '参数说明',
    },
    {
      title: '操作',
      width: '120',
      align: 'center',
      buttons: [
        {
          type: 'button',
          buttonType: 'primary',
          code: 'edit',
          name: '编辑',
          props: {
            type: 'primary',
          },

        },
        // {
        //   type: 'button',
        //   buttonType: 'link',
        //   code: 'delete',
        //   name: '删除',
        //   props: {
        //     type: 'primary',
        //   },

        // },
      ],
    },
  ],
  tableConfig: {
    border: true,
    // treeConfig: {
    //   children: 'children',
    // },
    idKey: 'id', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
