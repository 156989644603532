<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmparametermanage/list',
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {},
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {};
      if (val.code === 'add') {
        this.formConfig = {};
        this.modalConfig.title = '新增';
      }
      if (val.code === 'edit') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.modalConfig.title = '编辑';
      }
      this.openModal();
    },
    deleteClick({ val, row }) {
      let datas = [];
      if (this.selection.length === 0) {
        this.$message('请选择一条数据');
        return false;
      }
      datas = this.selection;

      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.post('/mdm/mdmparametermanage/delete', datas).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '删除成功',
              });

              this.getList();
            }
          });
        });
      return { val, row };
    },
  },
};
</script>
