<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import TableForm from './components';

formCreate.component('TableForm', TableForm);

export default {
  extends: Form,
  components: {
    TableForm,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'select',
          field: 'parameterModule',
          title: '所属模块',
          dictCode: 'module_group',
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择所属模块',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'parameterCode',
          title: '参数',
          value: '',
          props: {
            placeholder: '请输入参数',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入参数',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'parameterName',
          title: '参数名称',
          value: '',
          props: {
            placeholder: '请输入参数名称',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入参数',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'defaultValue',
          title: '默认值',
          value: '',
          props: {
            placeholder: '请输入默认值',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入参数',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'parameterValue',
          title: '参数值',
          value: '',
          props: {
            placeholder: '请输入参数值',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入参数值',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'parameterExplain',
          title: '参数说明',
          value: '',
          props: {
            placeholder: '请输入参数说明',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
            type: 'textarea',
          },
          validate: [
            {
              trigger: 'blur',
              required: false,
              message: '请输入参数说明',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
      ],
    };
  },
  mounted() {
    const len = Object.keys(this.formConfig).length;

    if (len === 0 || (len > 0 && this.formConfig.functionLevel === '0')) {
      this.hiddenFields(true, ['parentCode']);
    }
    console.log(this.formConfig);
    if (this.formConfig.id) {
      const data = {
        id: this.formConfig.id,
      };
      request.post('/mdm/mdmparametermanage/query', data)
        .then((res) => {
          console.log(res);
          this.setValue(res.result);
        });
    }
    // this.setValue({})
  },
  methods: {
    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmparametermanage/save';
        let params = formData;

        if (this.formConfig && this.formConfig.code === 'edit') {
          url = '/mdm/mdmparametermanage/update';
          params = Object.assign(this.formConfig, formData);
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
      console.log(formData);
    },
  },
};
</script>
